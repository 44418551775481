<template src="./OviAdministrator.html"> </template>

<script>
import Unity from "vue-unity-webgl";
export default {
  name: "ovi",
  components: { Unity },
  destroyed() {
      location.reload();
  },
  methods: {
    fullscreen() {
      this.$refs.myInstance.fullscreen()
    }
  }
};
</script>

<style lang="scss" scoped src="./OviAdministrator.scss"></style>
